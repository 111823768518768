<template>
    <div class="cert-group">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-tabs type="border-card">
                    <el-tab-pane label="Waiting to approve 8">
                        <el-table
                            :data="tableData"
                            style="width: 100%">
                            <el-table-column
                            prop="applicant_name"
                            label="Applicant Name"
                            width="200">
                            </el-table-column>
                            <el-table-column
                            prop="eCert_number"
                            label="ECert Number">
                            </el-table-column>
                            <el-table-column
                            prop="issue_date"
                            label="Issue Date">
                            </el-table-column>
                            <el-table-column
                            prop="campus_branch"
                            label="Campus/Branch">
                            </el-table-column>
                            <el-table-column
                            prop="requested_time"
                            label="Requested Time">
                            </el-table-column>
                            <el-table-column
                            label="Action">
                                <template #default="scope">
                                    <el-select v-model="scope.row.action" placeholder="请选择活动区域">
                                        <el-option label="Pending" value="Pending"></el-option>
                                        <el-option label="Approved" value="Approved"></el-option>
                                        <el-option label="Disapproved" value="Disapproved"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :span="24">
                <div class="mt-30 mb-30" align="center">
                    <el-button type="primary">Submit</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
  export default {
      data() {
        return {
          tableData: [{
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Pending'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Approved'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Disapproved'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Pending'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Disapproved'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Approved'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Pending'
          }, {
            applicant_name: 'Lorem ipsum dolor',
            eCert_number: 'HKUS20160114',
            issue_date: 'xx/xx/xxxx',
            campus_branch: 'Lorem ipsum',
            requested_time: 'xx/xx/xxxx xx:xx',
            action: 'Disapproved'
          }]
        }
      }
    }
</script>